/** @format */

import { HomeOutlined, ShoppingCartOutlined } from "@ant-design/icons";
import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  Breadcrumb,
  Button,
  Carousel,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Radio,
  Select,
  message,
} from "antd";
import valid from "card-validator";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddAddress from "../../../../Components/BakeryComponents/AddAddress";
import CheckoutForm from "../../../../Components/BakeryComponents/CheckoutForm";
import GuestOrderDetail from "../../../../Components/BakeryComponents/GuestOrderDetail";
import Navbar from "../../../../Components/BakeryComponents/Navbar";
import { Footer } from "../../../../Components/Footer/Footer";
import Layout from "../../../../Components/Layout/Layout";
import MyOrder from "../../../../Components/MyOrder/MyOrder";
import { openNotificationWithIcon } from "../../../../Components/Notification/Success.tsx";
import CheckoutLoading from "../../../../Components/Skeletons/CheckoutLoading";
import { getPickupDeliveryMessage } from "../../../../Helpers/checkoutHelpers";
import { validateAustralianNumber } from "../../../../Helpers/CreditCardValidation";
import { getCookie, getLocalStorage } from "../../../../Helpers/FrontendHelper";
import { navigateHelper } from "../../../../Helpers/NavigateHelper";
import useOnlineHelper from "../../../../Helpers/useOnlineHelper";

const { TextArea } = Input;
const { Option } = Select;
function Checkout() {
  const navigate = useNavigate();
  const paramValue = useParams();
  const stripe = useStripe();
  const {
    isDineIn,
    tableNameFromQuery,
    tableIdFromQuery,
    isCartEmpty,
    clearCartItems,
    navigateToHomePage,
    clearPhoneNumberDetailsFromLS,
  } = useOnlineHelper();
  const elements = useElements();
  const [value, setValue] = useState({});
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);

  const Token = getCookie(`token-${storeId}`);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const homePageData = useSelector((state) => state.storeReducer.homePageData);
  const bannerImages = useSelector(
    (state) =>
      state.storeReducer.storeData.bannerImagewithBannerTypeListViewModels
  );

  const paymentOption = Form.useWatch("paymentOption", form);
  const cardNumberValidator = (_, value) => {
    if (valid.number(value).isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid Credit Card Number"));
    }
  };
  const cvcValidator = (_, value) => {
    if (valid.cvv(value).isValid) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Invalid CVC Number"));
    }
  };
  // const homePageData = useSelector((state) => state.storeReducer.homePageData);

  useEffect(() => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = homePageData?.storeDetails?.image
      ? homePageData?.storeDetails?.image
      : storeState == "restaurant"
      ? "https://posapt.au/assets/dist/image/restaurant.png"
      : storeState == "normal"
      ? "https://posapt.au/assets/dist/image/grocery.png"
      : "";
    document.title = homePageData?.storeDetails?.storeName
      ? homePageData?.storeDetails?.storeName
      : "Online Order";
  }, [homePageData]);

  const {
    deliveryChangeSuccess,
    deliveryPrice,
    isInitiateOrderSuccess,
    isOrderSuccess,
    isInitiateOrderSuccess1,
    orderSucceedDetails,
    isOrderAlreadyPlaced,
    isLoading,
    orderId,
    getCheckoutSectionLoading,
    checkoutData,
    checkoutAmounts,
    voucherDiscount,
  } = useSelector((state) => state.checkout);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const countryList = useSelector(
    (state) => state.storeReducer.homePageData?.listCountry
  );
  const {
    checkoutSectionList,
    deliveryLocationGuest,
    checkoutLoading,
  } = useSelector((state) => state.checkout);
  const name = Form.useWatch("name", form);
  const cardNumber = Form.useWatch("cardNumber", form);
  const mm = Form.useWatch("mm", form);
  const yy = Form.useWatch("yy", form);
  const cvc = Form.useWatch("cvc", form);
  const expiry = Form.useWatch("expiry", form);

  const dateFormat = useSelector(
    (state) => state.storeReducer.storeData.dateFormat
  );
  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );
  const halfHalfProducts = useSelector(
    (state) => state.StateManager.halfHalfProducts
  );
  // const [isModalVisible, setISModalVisible] = useState(false);
  const [isThankYouModal, setIsThankYouModal] = useState(false);
  const [isAddAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [selectedWeekDay, setSelectedWeekDay] = useState(null);
  const selectedDate = Form.useWatch("deliveryDate", form);
  const [
    isGuestOrderDetailModalVisible,
    setIsGuestOrderDetailModalVisible,
  ] = useState(false);
  const [isOrderPlacedModalVisible, setIsOrderPlacedModalVisible] = useState(
    false
  );

  const [method, setMethod] = useState("");
  const [stripeLoading, setStripeLoading] = useState(false);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  useEffect(() => {
    if (storeId) {
      dispatch({
        type: "GUEST_CHECKOUT_SECTION_LIST_REQUEST",
        payload: {
          storeId,
        },
      });
    }
  }, [storeId]);
  useEffect(() => {
    if (deliveryChangeSuccess) {
      dispatch({
        type: "GUEST_CHECKOUT_SECTION_LIST_REQUEST",
        payload: {
          storeId,

          token: getCookie(`token-${storeId}`),
        },
      });
    }
  }, [deliveryChangeSuccess]);
  const [selectedDeliveryLocation, setSelectedDeliveryLocation] = useState();
  useEffect(() => {
    if (deliveryLocationGuest) {
      setSelectedDeliveryLocation(deliveryLocationGuest[0]);
    }
  }, [deliveryLocationGuest]);
  const query = useQuery();
  const deliveryMethod = query.get("deliveryMethod");

  useEffect(() => {
    const deliveryMethod = query.get("deliveryMethod");
    if (deliveryMethod == 1) {
      setMethod("Pick Up ");
    } else if (deliveryMethod == 2) {
      setMethod("Delivery ");
    } else if (deliveryMethod == 3) {
      setMethod("Dine in ");
    }
  }, [deliveryMethod]);
  const getDiscountAmount = (discount, tax) => {
    if (homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3") {
      return discount;
    } else if (
      homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "2"
    ) {
      return discount - tax;
    } else {
      return "";
    }
  };

  const calucateTaxHandler = (quantity, price, item) => {
    if (homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3") {
      return (
        (parseFloat(price * quantity) * item?.taxExclusiveInclusiveValue) / 100
      );
    } else if (
      homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "2"
    ) {
      return (
        (parseFloat(price * quantity) * item?.taxExclusiveInclusiveValue) /
        (1 + item?.taxExclusiveInclusiveValue)
      );
    } else {
      return "0";
    }
  };

  const placeOrderHandler = async (values) => {
    if (deliveryMethod == "2") {
      if (!selectedDeliveryLocation?.DeliveryLocation) {
        return dispatch(
          openNotificationWithIcon(
            "error",
            "Please select delivery address first !"
          )
        );
      }
    }

    let paymentMethodStripe = null;
    const isCardPayment =
      checkoutSectionList?.find(
        (option) => option.paymentMethodId == paymentOption
      )?.type == "7";

    if (isCardPayment) {
      if (!stripe || !elements) {
        message.error("Stripe has not been loaded !");
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      if (!cardElement) {
        message.error("Card has not been loaded !");
        return;
      }
      setStripeLoading(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
        billing_details: {
          email: form.getFieldValue("stripeEmail"),
        },
      });
      paymentMethodStripe = paymentMethod;
      setStripeLoading(false);

      if (error) {
        setStripeLoading(false);
        message.error(error.message);
        return;
      }
    }

    const localStorageData = getLocalStorage(`${storeId}-${storeState}`);

    dispatch({
      type: "PLACE_ORDER_REQUEST_GUEST",
      payload: {
        StoreId: storeId,
        OrderId: orderSucceedDetails.orderId ? orderSucceedDetails.orderId : "",
        OrderTypeId: homePageData.deliveryMethod?.find(
          (item) => item.deliveryMethodValue == deliveryMethod
        )?.id,
        PaymentMethodId: values.paymentOption,
        PaymentMethodStripeId: paymentMethodStripe
          ? paymentMethodStripe.id
          : null,
        UserId: "",
        DiscountPercentage: voucherDiscount ?? null,
        CustomerViewModel:
          query.get("deliveryMethod") == "3" && query.get("TableId")
            ? null
            : {
                Id: "",
                Name: values.name,
                Email: values.Email,
                PhoneNumber: values.phone,
                CountryPhoneNumberPrefixId: values.prefix,
                CountryId: values.country,
                PostalCode: values.postalCode,
              },
        TableId: tableIdFromQuery,
        Description: values.description,
        DiscountAmountWithTax: checkoutAmounts?.voucherDiscount?.toString(),
        DiscountAmount: checkoutAmounts?.discountTax
          ? getDiscountAmount(
              parseFloat(checkoutAmounts?.voucherDiscount),
              parseFloat(checkoutAmounts?.discountTax)
            )
              .toFixed(2)
              .toString()
          : "",
        TaxAmount: checkoutAmounts?.taxAmount?.toString(),

        // CreditCardDetails:
        //   checkoutSectionList?.find(
        //     (option) => option.paymentMethodId == paymentOption
        //   )?.type == "7"
        //     ? {
        //         NameOnCard: name,
        //         CardNumber: cardNumber?.replace(/\s/g, ""),
        //         CVCNumber: cvc,
        //         ExpiryMonth: expiry?.split("/")[0],
        //         ExpiryYear: expiry?.split("/")[1],
        //       }
        //     : null,
        TotalWithoutTaxAmount:
          homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
            ? checkoutAmounts?.subTotalAmount?.toString()
            : (
                checkoutAmounts?.subTotalAmount -
                checkoutAmounts.taxAmountWithoutDeliveryAndDiscountTax
              )
                .toFixed(2)
                .toString(),
        TotalAmount: checkoutAmounts?.totalAmount?.toString(),
        DeliveryAmount:
          query.get("deliveryMethod") == "2"
            ? deliveryPrice
              ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
                ? deliveryPrice
                  ? deliveryPrice
                  : ""
                : (
                    parseFloat(deliveryPrice) -
                    parseFloat(checkoutAmounts.deliveryTax)
                  )
                    .toFixed(2)
                    .toString()
              : ""
            : "",
        DeliveryAmountWithTax:
          query.get("deliveryMethod") == "2"
            ? deliveryPrice
              ? homePageData?.storeTaxSettings?.taxExclusiveInclusiveType == "3"
                ? deliveryPrice
                  ? (
                      parseFloat(deliveryPrice) +
                      parseFloat(checkoutAmounts.deliveryTax)
                    )
                      .toFixed(2)
                      .toString()
                  : ""
                : parseFloat(deliveryPrice)
                    .toFixed(2)
                    .toString()
              : ""
            : "",

        DeliveryAddress:
          query.get("deliveryMethod") == "2"
            ? selectedDeliveryLocation?.DeliveryLocation
              ? selectedDeliveryLocation?.DeliveryLocation
              : ""
            : "",

        PickUpDeliveryDateTime:
          query.get("deliveryMethod") !== "3"
            ? dateFormat == "yyyy/MM/dd HH:mm:ss"
              ? moment(values.deliveryDate).format("YYYY/MM/DD") +
                " " +
                values.time
              : dateFormat == "MM/dd/yyyy HH:mm:ss"
              ? moment(values.deliveryDate).format("MM/DD/YYYY") +
                " " +
                values.time
              : dateFormat == "dd/MM/yyyy HH:mm:ss"
              ? moment(values.deliveryDate).format("DD/MM/YYYY") +
                " " +
                values.time
              : ""
            : "",
        OrderDetails: localStorageData.orderDetails
          ?.concat(halfHalfProducts)
          ?.map((item) => {
            return {
              ProductId: item?.id,
              ProductVariationId: item?.productVariations[0]?.id,
              OrderItemsPriceModifierViewModels:
                item?.productVariations[0]?.OrderItemsPriceModifierViewModels ??
                [],
              RemovedOrderItemsIngredientsViewModels:
                item.productVariations[0]
                  ?.RemovedOrderItemsIngredientsViewModels ?? [],
              OrderItemsSpiceChoiceViewModels: item?.productSpiceChoices?.name
                ? item?.productSpiceChoices
                : null,
              IsHalfGroup: item.isHalfCategory,
              Quantity: item.isHalfCategory
                ? item.Quantity?.toString()
                : item?.productVariations[0]?.quantity.toString(),
              Description: item.description,
              HalfGroupAmount:
                item.HalfGroupAmount?.toFixed(2).toString() ?? "",
              HalfGroupKey: item.HalfGroupKey ?? "",
              Total: (
                item.productVariations[0]?.quantity *
                item.productVariations[0]?.price
              )
                .toFixed(2)
                .toString(),
              TotalOriginalSellingAmount: item.productVariations[0]?.price?.toString(), //added
              DiscountWithoutTax: item.productVariations[0]?.discount
                ? (
                    item.productVariations[0]?.quantity *
                    parseFloat(item.productVariations[0]?.discount)
                  )
                    ?.toFixed(2)
                    .toString()
                : (0).toFixed(2).toString(), //added
              TotalTax: calucateTaxHandler(
                item.productVariations[0]?.quantity,
                item.productVariations[0]?.actualPrice,
                item
              )
                .toFixed(2)
                .toString(), //added
              UnitCost: item.productVariations[0]?.unitPrice, //added
            };
          }),
      },
    });
  };
  // useEffect(() => {
  //   if (orderSucceedDetails.isOrderCompleted) {
  //     setIsThankYouModal(true);
  //     const localStorageData = getLocalStorage(`${storeId}-${storeState}`);
  //     dispatch({
  //       type: "CHANGE_LOCAL_STORAGE_DATA",
  //       payload: {
  //         newData: {
  //           ...localStorageData,
  //           orderDetails: [],
  //         },
  //       },
  //     });
  //     setLocalStorage(`${storeId}-${storeState}`, {
  //       ...localStorageData,
  //       orderDetails: [],
  //     });
  //     dispatch({
  //       type: "CLEAR_HALF_HALF_PRODUCTS",
  //     });
  //     form.resetFields();
  //   }
  //   if (isInitiateOrderSuccess1) {
  //     setIsThankYouModal(true);
  //     const localStorageData = getLocalStorage(`${storeId}-${storeState}`);
  //     dispatch({
  //       type: "CHANGE_LOCAL_STORAGE_DATA",
  //       payload: {
  //         newData: {
  //           ...localStorageData,
  //           orderDetails: [],
  //         },
  //       },
  //     });
  //     setLocalStorage(`${storeId}-${storeState}`, {
  //       ...localStorageData,
  //       orderDetails: [],
  //     });
  //     dispatch({
  //       type: "CLEAR_HALF_HALF_PRODUCTS",
  //     });
  //     form.resetFields();
  //   }
  // }, [
  //   isInitiateOrderSuccess,
  //   isInitiateOrderSuccess1,
  //   orderSucceedDetails.isOrderCompleted,
  // ]);
  useEffect(() => {
    if (isOrderAlreadyPlaced) {
      setIsOrderPlacedModalVisible(true);
    } else {
      setIsOrderPlacedModalVisible(false);
    }
  }, [isOrderAlreadyPlaced]);

  const updatedCartData = getLocalStorage(`${storeId}-${storeState}`);
  const getTimeArray = () => {
    let arr = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        arr.push({
          label: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}`,
          value: `${i < 10 ? "0" + i : i}:${j === 0 ? `00` : 15 * j}:00`,
        });
      }
    }

    let startIndex;
    let endIndex;
    if (deliveryMethod == 1) {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.openHours
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.pickUpHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.closeHours
        );
      });
    } else if (deliveryMethod == 2) {
      startIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.openHours
        );
      });
      endIndex = arr.findIndex((item) => {
        return (
          item.label ==
          checkoutData?.deliveryHours?.find(
            (item) => item.weekDayName.toLowerCase() == selectedWeekDay
          )?.closeHours
        );
      });
    }
    arr = arr.filter((item, index) => index >= startIndex && index <= endIndex);

    return arr;
  };
  const backToHomeHandler = () => {
    navigate(`${navigateHelper(paramValue.type, paramValue.storeName, true)}`);
    dispatch({
      type: "CLEAR_ORDER_ALREADY_PLACED_STATE",
    });
  };
  useEffect(() => {
    if (isOrderSuccess) {
      dispatch({
        type: "REMOVE_CHECK_VOUCHER",
      });
      // clear phone number data from localstorage

      clearPhoneNumberDetailsFromLS();
      clearCartItems();

      form.resetFields();
      if (isDineIn) {
        navigate(
          `${navigateHelper(
            paramValue.type,
            paramValue.storeName
          )}/current-order?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&OrderId=${
            orderSucceedDetails.orderId
          }`
        );
      } else {
        setIsThankYouModal(true);
      }
    }
  }, [isOrderSuccess]);
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{
          width: 120,
        }}
      >
        {countryList.length > 0 &&
          countryList?.map((item) => (
            <Option value={item.id}>
              {" "}
              <span style={{ fontSize: "14px" }}>
                <img
                  src={item.image}
                  style={{ height: "20px", width: "30px", marginRight: "4px" }}
                />
                {item.additionalValue}
              </span>
            </Option>
          ))}
      </Select>
    </Form.Item>
  );
  useEffect(() => {
    setSelectedWeekDay(
      moment(selectedDate)
        .format("dddd")
        ?.toLowerCase("")
    );
  }, [selectedDate]);

  return (
    <>
      <Layout
        myOrderComponent={
          <MyOrder
            deliveryMethod={deliveryMethod}
            deliveryPrice={deliveryPrice}
            selectedDeliveryLocation={selectedDeliveryLocation}
            activeDeliveryMethod={query.get("deliveryMethod")}
          />
        }
      >
        <Navbar
          homePageData={homePageData}
          storeState={storeState}
          updatedCartData={updatedCartData}
          showSearchIcon={false}
        />
        {/* ***** Header Area End ***** */}
        {/* vertical tab */}
        <div className="container">
          <div className="topslider home_slider  d-none d-md-block " />
          <Carousel>
            {bannerImages?.find((item) => item.bannerType == 3)?.banners[0]
              ?.image ? (
              bannerImages
                ?.find((item) => item.bannerType == 3)
                ?.banners.map((item) => {
                  return (
                    <div>
                      <img
                        src={item.image}
                        className="d-block w-100 banner-image"
                        alt="..."
                      />
                    </div>
                  );
                })
            ) : (
              <div>
                <img
                  src={"Assets/assets/images/checkout.png"}
                  className="d-block w-100"
                  alt="..."
                />
              </div>
            )}
          </Carousel>
          <div className="">
            <Breadcrumb className="mt-2 d-md-none d-block">
              <Breadcrumb.Item>
                <HomeOutlined />

                <span className="mt-4" onClick={() => navigateToHomePage()}>
                  Home
                </span>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <ShoppingCartOutlined />
                <span className="mt-4">Checkout</span>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <Form
          className="login"
          name="form"
          onFinish={placeOrderHandler}
          autoComplete="off"
          initialValues={{
            remember: true,
          }}
          fields={[
            {
              name: ["prefix"],
              value:
                countryList.length > 0 &&
                countryList?.find(
                  (item) =>
                    item.additionalValue ==
                    localStorage.getItem("phoneNumberPrefix")
                )?.id,
            },
            {
              name: ["country"],
              value:
                countryList.length > 0 &&
                countryList?.find((item) => item.isSelected)?.id,
            },
            {
              name: ["phone"],
              value: localStorage.getItem("phoneNumber"),
            },
          ]}
          form={form}
        >
          <section className="pt-3 pb-3">
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-12">
                  {getCheckoutSectionLoading ? (
                    <CheckoutLoading
                      deliveryMethod={deliveryMethod}
                      query={query}
                      guestCheckout={true}
                    />
                  ) : (
                    <div className="checkout_left">
                      {!isDineIn && (
                        <>
                          {deliveryMethod == 2 && (
                            <div className="card newaddress">
                              <div className="card-header fw-bold text-red">
                                DELIVERY ADDRESS
                              </div>
                              <div className="card-body d-flex-row">
                                <div className="addresss1">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="bg-light p-3 rounded-3">
                                        <blockquote className="blockquote mb-2 pb-2">
                                          <p className="mb-0 fw-bold fs-6">
                                            Delivery Address
                                            <span>
                                              {" "}
                                              |{" "}
                                              <a className="text-red1">
                                                <small
                                                  className="text-danger"
                                                  onClick={() => {
                                                    setIsAddressModalVisible(
                                                      true
                                                    );
                                                  }}
                                                >
                                                  Add New Address
                                                </small>
                                              </a>
                                            </span>
                                          </p>
                                        </blockquote>
                                        {deliveryLocationGuest == null ||
                                        deliveryLocationGuest.length == 0 ? (
                                          <label
                                            className="form-check-label fw-bold"
                                            htmlFor="flexRadioDefault1"
                                          >
                                            No delivery address available
                                          </label>
                                        ) : (
                                          deliveryLocationGuest?.map(
                                            (location, i) => {
                                              return (
                                                <>
                                                  <div class="row" key={i}>
                                                    <div class="col-md-6">
                                                      <div
                                                        className="form-check"
                                                        key={i}
                                                      >
                                                        <Checkbox
                                                          style={{
                                                            marginRight: "8px",
                                                          }}
                                                          checked={
                                                            location ==
                                                            selectedDeliveryLocation
                                                          }
                                                          onChange={(e) => {
                                                            if (
                                                              e.target.checked
                                                            ) {
                                                              setSelectedDeliveryLocation(
                                                                location
                                                              );
                                                            } else {
                                                              setSelectedDeliveryLocation();
                                                            }
                                                          }}
                                                          name="flexRadioDefault-h"
                                                          id={
                                                            "flexRadioDefaultd" +
                                                            i
                                                          }
                                                        />
                                                        <label
                                                          className="form-check-label fw-bold"
                                                          htmlFor={
                                                            "flexRadioDefaultd" +
                                                            i
                                                          }
                                                        >
                                                          {
                                                            location.DeliveryLocation
                                                          }
                                                        </label>
                                                      </div>
                                                    </div>
                                                    <div class="col-md-6 text-end">
                                                      <div class="deleteform" />
                                                    </div>
                                                  </div>
                                                  <hr />
                                                </>
                                              );
                                            }
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                          <div className="card border-0 checkout-card mt-3">
                            <div className="card-header fw-bold text-red">
                              {method} Date and Time
                            </div>
                            <div className="card-body d-flex-row">
                              <div className="addresss1">
                                <div className="row">
                                  <div className="col-md-12">
                                    {getPickupDeliveryMessage(
                                      query.get("deliveryMethod"),
                                      selectedWeekDay,
                                      checkoutData
                                    ) && (
                                      <div
                                        class="alert alert2 alert-danger mt-2 d-flex align-items-center justify-content-between"
                                        role="alert"
                                      >
                                        {getPickupDeliveryMessage(
                                          query.get("deliveryMethod"),
                                          selectedWeekDay,
                                          checkoutData
                                        )}
                                      </div>
                                    )}

                                    <div className="bg-light p-3 mt-3">
                                      <div className="form-details inner-bg-light">
                                        <div className="row text-start">
                                          {query.get("deliveryMethod") !==
                                            "3" && (
                                            <>
                                              {" "}
                                              <div className={"col-md-3"}>
                                                <Form.Item
                                                  label={`${method} date`}
                                                  name="deliveryDate"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: `Please input your ${method?.toLowerCase()} date!`,
                                                    },
                                                  ]}
                                                >
                                                  <DatePicker
                                                    format={dateFormat
                                                      .split(" ")[0]
                                                      ?.toUpperCase()}
                                                    className="checkout-date-picker"
                                                    placeholder={`${method} Date`}
                                                    style={{
                                                      width: "100%",
                                                      height: "40px",
                                                    }}
                                                  />
                                                </Form.Item>
                                              </div>
                                              <div className={"col-md-3"}>
                                                <Form.Item
                                                  label={`${method} time`}
                                                  name="time"
                                                  rules={[
                                                    {
                                                      required: true,
                                                      message: `Please input your ${method?.toLowerCase()} time!`,
                                                    },
                                                  ]}
                                                >
                                                  <Select
                                                    disabled={!selectedDate}
                                                    options={getTimeArray(
                                                      query.get(
                                                        "deliveryMethod"
                                                      ),
                                                      selectedWeekDay,
                                                      checkoutData
                                                    )}
                                                    placeholder="Select Time"
                                                  />
                                                </Form.Item>
                                              </div>
                                            </>
                                          )}

                                          <div className={"col-md-6"}>
                                            <Form.Item
                                              label="Email Address"
                                              name="Email"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your email!",
                                                },
                                                {
                                                  type: "email",
                                                  message:
                                                    "Please enter valid email!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Email"
                                                class="form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className={"col-md-6"}>
                                            <Form.Item
                                              label="Postal Code"
                                              name="postalCode"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your postal code !",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Postal Code"
                                                class="form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div class=" col-md-6 ">
                                            <Form.Item
                                              label="Country"
                                              name="country"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input Country !",
                                                },
                                              ]}
                                            >
                                              <Select
                                                style={{
                                                  height: "40px",
                                                  paddingBottom: "1rem",
                                                }}
                                              >
                                                {countryList.length > 0 &&
                                                  countryList?.map((item) => (
                                                    <Option
                                                      value={item.id}
                                                      key={item.id}
                                                    >
                                                      {" "}
                                                      <span
                                                        style={{
                                                          fontSize: "14px",
                                                        }}
                                                      >
                                                        <img
                                                          src={item.image}
                                                          style={{
                                                            height: "20px",
                                                            width: "30px",
                                                            marginRight: "4px",
                                                          }}
                                                        />
                                                        {item.value}
                                                      </span>
                                                    </Option>
                                                  ))}
                                              </Select>
                                            </Form.Item>
                                          </div>
                                          <div className="col-md-6">
                                            <Form.Item
                                              label="Phone Number"
                                              name="phone"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your phone!",
                                                },
                                                {
                                                  type: "phone",
                                                  message:
                                                    "Please enter valid phone number!",
                                                },
                                                {
                                                  validator: validateAustralianNumber,
                                                },
                                              ]}
                                            >
                                              <Input
                                                readOnly
                                                addonBefore={prefixSelector}
                                                placeholder="Enter Phone"
                                                class="form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                          <div className="col-md-6">
                                            <Form.Item
                                              label="Full Name"
                                              name="name"
                                              rules={[
                                                {
                                                  required: true,
                                                  message:
                                                    "Please input your full name!",
                                                },
                                              ]}
                                            >
                                              <Input
                                                placeholder="Enter Your Name"
                                                class="form-control"
                                              />
                                            </Form.Item>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="card checkout-card border-0 mt-3">
                            <div className="card-header fw-bold text-red">
                              Payment Options
                            </div>
                            <div className="card-body d-flex-row">
                              <div className="addresss1">
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Item
                                      name="paymentOption"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please input one of the given payment options !",
                                        },
                                      ]}
                                    >
                                      <Radio.Group>
                                        {query.get("deliveryMethod") == "2" &&
                                          checkoutSectionList?.map(
                                            (option, i) => {
                                              if (
                                                option.name == "Payment" &&
                                                checkoutData?.showPaymentOnDelivery
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {option.name == "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              } else if (
                                                option.name != "Payment"
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {option.name == "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              }
                                            }
                                          )}

                                        {deliveryMethod == "3" &&
                                          checkoutSectionList?.map(
                                            (option, i) => {
                                              if (
                                                option.name == "Payment" &&
                                                checkoutData?.showPaymentOnDineIn
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {deliveryMethod == "3" &&
                                                      option.name ==
                                                        "Payment" &&
                                                      " on counter"}
                                                    {!deliveryMethod == "3" &&
                                                      option.name ==
                                                        "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              } else if (
                                                option.name != "Payment"
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {option.name == "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              }
                                            }
                                          )}
                                        {deliveryMethod == "1" &&
                                          checkoutSectionList?.map(
                                            (option, i) => {
                                              if (
                                                option.name == "Payment" &&
                                                checkoutData?.showPaymentOnPickUp
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {option.name == "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              } else if (
                                                option.name != "Payment"
                                              ) {
                                                return (
                                                  <Radio
                                                    value={
                                                      option.paymentMethodId
                                                    }
                                                  >
                                                    {" "}
                                                    {option.name}
                                                    {option.name == "Payment" &&
                                                      " on " + method}
                                                  </Radio>
                                                );
                                              }
                                            }
                                          )}
                                      </Radio.Group>
                                    </Form.Item>
                                    {checkoutSectionList?.find(
                                      (option) =>
                                        option.paymentMethodId == paymentOption
                                    )?.type == "7" && (
                                      <div className="m-3">
                                        <div className="row">
                                          <CheckoutForm
                                            form={form}
                                            CardNumberElement={
                                              CardNumberElement
                                            }
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {isDineIn && (
                        <div className="card checkout-card border-0 mt-3">
                          <div className="card-header fw-bold text-red">
                            Dine In
                          </div>
                          <div className="card-body d-flex-row d-flex justify-content-between">
                            <p>Table No:</p>
                            <p className="fw-bold">{tableNameFromQuery}</p>
                          </div>
                        </div>
                      )}

                      <div className="card border-0 checkout-card mt-3">
                        <div className="card-header fw-bold text-red">
                          Instruction Or Special Request
                        </div>
                        <div className="card-body d-flex-row">
                          <div className="addresss1">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="row">
                                  <div className="col-md-12">
                                    <Form.Item name="description">
                                      <TextArea
                                        placeholder="Enter Instruction Or Special Request"
                                        class="form-control"
                                      />
                                    </Form.Item>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mybtn d-flex justify-content-end align-items-start mt-3">
                            {/* <Button
                              onClick={() => {
                                navigate(
                                  query.get("TableId")
                                    ? `${navigateHelper(
                                        paramValue.type,
                                        paramValue.storeName
                                      )}?TableId=${query.get("TableId")}`
                                    : `${navigateHelper(
                                        paramValue.type,
                                        paramValue.storeName,
                                        true
                                      )}`
                                );
                              }}
                              type="primary"
                              className="secondary-button"
                              style={{
                                border: "none",
                                color: "white",
                              }}
                            >
                              Back
                            </Button> */}
                            <Form.Item>
                              <Button
                                disabled={
                                  storesCartData?.orderDetails?.length == 0 &&
                                  halfHalfProducts.length == 0
                                    ? true
                                    : false
                                }
                                type="primary"
                                htmlType="submit"
                                loading={checkoutLoading || stripeLoading}
                                className="primary-button"
                                style={{
                                  border: "none",
                                  color: "white",
                                  height: "42px",
                                  padding: "0px 40px",
                                  fontWeight: "bold",
                                  pointerEvents: isCartEmpty && "none",
                                  opacity: isCartEmpty && 0.5,
                                }}
                              >
                                Place Order
                              </Button>
                            </Form.Item>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </Form>
        <Footer />
        {/* modal */}
        <Modal
          show={isAddAddressModalVisible}
          onHide={() => {
            setIsAddressModalVisible(false);
          }}
          footer={null}
          style={{ top: "2rem" }}
        >
          <AddAddress
            isGuest={true}
            value={value}
            setValue={setValue}
            setIsAddressModalVisible={setIsAddressModalVisible}
          />
        </Modal>
        <Modal
          style={{ top: "2rem" }}
          size="xl"
          className="guest-order-detail-modal"
          show={isGuestOrderDetailModalVisible}
          backdrop="static"
          footer={null}
        >
          <GuestOrderDetail orderId={orderId} />
          <Modal.Footer>
            <div>
              <Button danger onClick={backToHomeHandler}>
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal show={isThankYouModal} backdrop="static" footer={null}>
          {" "}
          <div className="modal-content">
            <div className="modal-body bg-transparent">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                id="order-heading"
              >
                <div className="text-uppercase">
                  <p>Order detail</p>
                </div>
                <div className="h4">{orderSucceedDetails.orderedDate}</div>
                <div className="pt-1">
                  <p>
                    {orderSucceedDetails.orderNumber} is currently
                    <b className="text-dark"> processing</b>
                  </p>
                </div>
              </div>
              <div className="order-confirm text-center">
                <img src="https://img.icons8.com/carbon-copy/100/000000/checked-checkbox.png" />
                <h2 className="text-theme"> Thank You For Your Order! </h2>
                <p>Your Order has been Confirmed</p>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "1rem",
                }}
              >
                <Button
                  style={{
                    borderRadius: "5px",

                    marginTop: "0.5rem",
                  }}
                  onClick={backToHomeHandler}
                  danger
                >
                  Back To Home
                </Button>

                <Button
                  style={{
                    borderRadius: "5px",

                    marginTop: "0.5rem",
                  }}
                  type="primary"
                  danger
                  onClick={() => {
                    dispatch({
                      type: "CLEAR_ORDER_ALREADY_PLACED_STATE",
                    });
                    setIsGuestOrderDetailModalVisible(true);
                    setIsThankYouModal(false);
                  }}
                >
                  View Order
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </Layout>
    </>
  );
}

export default Checkout;
