/** @format */

import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getLocalStorage, setLocalStorage } from "./FrontendHelper";
import { navigateHelper } from "./NavigateHelper";

function useOnlineHelper() {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paramValue = useParams();

  function useQuery() {
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  const storesCartData = useSelector(
    (state) => state.StateManager.storesCartData
  );
  const storeId = useSelector((state) => state.storeReducer.storeData.storeId);
  const storeState = useSelector(
    (state) => state.storeReducer.storeData.templateName
  );
  const { homePageData } = useSelector((state) => state.storeReducer);

  const parseQueryParam = (param) => {
    const value = query.get(param);
    return value === "null" || value === "undefined" ? null : value;
  };

  const isDineIn = query.get("deliveryMethod") === "3";
  const isPickup = query.get("deliveryMethod") === "1";
  const isDelivery = query.get("deliveryMethod") === "2";
  const tableIdFromQuery = parseQueryParam("TableId");
  const tableNameFromQuery = parseQueryParam("TableName");
  const orderIdFromQuery = parseQueryParam("OrderId");
  const orderIdFromApi = homePageData?.orderId;
  const homePageUrl = tableIdFromQuery
    ? `${navigateHelper(
        paramValue.type,
        paramValue.storeName,
        true
      )}?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&OrderId=${orderIdFromQuery}`
    : `${navigateHelper(paramValue.type, paramValue.storeName, true)}`;

  const deliveryMethodFromParam = query.get("deliveryMethod");

  const isHomePage =
    window.location.pathname === "/" ||
    window.location.pathname === `/${paramValue.type}/${paramValue.storeName}`;

  const isCartEmpty =
    !storesCartData?.orderDetails ||
    storesCartData.orderDetails.length === 0 ||
    !getLocalStorage(`${storeId}-${storeState}`)?.orderDetails.length > 0;

  const isInGuestCheckoutRoute =
    window.location.pathname ==
      `/${paramValue.type}/${paramValue.storeName}/guestCheckout` ||
    window.location.pathname ==
      `/${paramValue.type}/${paramValue.storeName}/guestcheckout` ||
    window.location.pathname == "/guestcheckout" ||
    window.location.pathname == "/guestCheckout";

  const isInCheckoutRoute =
    window.location.pathname ==
      `/${paramValue.type}/${paramValue.storeName}/checkout` ||
    window.location.pathname == `/checkout`;

  const clearCartItems = () => {
    const localStorageData = getLocalStorage(`${storeId}-${storeState}`);

    dispatch({
      type: "CLEAR_LOCAL_STORAGE_DATA",
    });
    dispatch({
      type: "CLEAR_HALF_HALF_PRODUCTS",
    });
    setLocalStorage(`${storeId}-${storeState}`, {
      ...localStorageData,
      orderDetails: [],
    });
  };
  const clearPhoneNumberDetailsFromLS = () => {
    localStorage.removeItem("phoneNumber");
    localStorage.removeItem("phoneNumberPrefix");
    localStorage.removeItem("isPhoneNumberValidated");
  };

  const navigateToHomePage = () => {
    navigate(
      tableIdFromQuery
        ? `${navigateHelper(
            paramValue.type,
            paramValue.storeName,
            true
          )}?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&OrderId=${orderIdFromQuery}`
        : `${navigateHelper(paramValue.type, paramValue.storeName, true)}`
    );
  };

  const navigateToCheckoutPage = (deliveryMethod) => {
    console.log("--delivery Method", deliveryMethod);
    tableIdFromQuery
      ? navigate(
          `${navigateHelper(
            paramValue.type,
            paramValue.storeName
          )}/checkout?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&deliveryMethod=${deliveryMethod}&OrderId=${orderIdFromQuery}`
        )
      : navigate(
          `${navigateHelper(
            paramValue.type,
            paramValue.storeName
          )}/checkout?deliveryMethod=${deliveryMethod}`
        );
  };
  const navigateToGuestCheckoutPage = (deliveryMethod) => {
    if (tableIdFromQuery) {
      navigate(
        `${navigateHelper(
          paramValue.type,
          paramValue.storeName
        )}/guestcheckout?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&deliveryMethod=${deliveryMethod}&OrderId=${orderIdFromQuery}`
      );
    } else {
      navigate(
        `${navigateHelper(
          paramValue.type,
          paramValue.storeName
        )}/guestcheckout?deliveryMethod=${deliveryMethod}`
      );
    }
  };

  const navigateToProfile = () => {
    tableIdFromQuery
      ? navigate(
          `${navigateHelper(
            paramValue.type,
            paramValue.storeName
          )}/profile?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&OrderId=${orderIdFromQuery}`
        )
      : navigate(
          `${navigateHelper(paramValue.type, paramValue.storeName)}/profile`
        );
  };

  const isDineTrackOrderDisplay = () => {
    return (orderIdFromQuery || orderIdFromApi) && tableIdFromQuery;
  };

  const trackOrderClickHandler = () => {
    if (!orderIdFromQuery && orderIdFromApi && orderIdFromQuery == "null") {
      query.set("OrderId", orderIdFromApi);
    }
    navigate(
      `${navigateHelper(
        paramValue.type,
        paramValue.storeName
      )}/current-order?TableId=${tableIdFromQuery}&TableName=${tableNameFromQuery}&OrderId=${orderIdFromQuery ||
        orderIdFromApi}`
    );
  };

  return {
    isDineIn,
    isPickup,
    isDelivery,
    tableIdFromQuery,
    deliveryMethodFromParam,
    isHomePage,
    isCartEmpty,
    isInGuestCheckoutRoute,
    isInCheckoutRoute,
    tableNameFromQuery,
    orderIdFromQuery,
    clearCartItems,
    clearPhoneNumberDetailsFromLS,
    navigateToHomePage,
    isDineTrackOrderDisplay,
    trackOrderClickHandler,
    navigateToCheckoutPage,
    navigateToGuestCheckoutPage,
    homePageUrl,
    navigateToProfile,
  };
}

export default useOnlineHelper;
